import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import LandingHeader from "../../components/Headers/LandingHeader";
import HomeCard from "../../components/Cards/HomeCard";
import Navbar from "components/Navbars/AuthNavbar";
import Footerbn from "pages/Footer/Footerbn";
import TourDetail from "../../components/TourDetails";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "BaseUrl";
import ReviewSection from "components/Reviews/ReviewSection";
import Travel from "../../components/Locations/Travel";
import Influencers from "pages/influencer";
import Services from "pages/servicesweoffer/Services";
import whyChooseUSbg from "../../assets/kkraimg/whyChooseUSHome.png"
import { toast } from "react-toastify";

export default function LandingPage() {
  const navigate = useNavigate();
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [confirmedSearchTerm, setConfirmedSearchTerm] = useState("");

  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [Reviews, setReviews] = useState([]);
  const [locations, setLocations] = useState([]);


  const calculateAverageRating = (packageTitle) => {
    const relatedReviews = Reviews.filter(review => review.pakagetitle === packageTitle);

    if (relatedReviews.length === 0) return "No Reviews";

    const totalRating = relatedReviews.reduce((sum, review) => sum + (Number(review.review_start) || 0), 0);
    const averageRating = totalRating / relatedReviews.length;

    return !isNaN(averageRating) ? averageRating.toFixed(1) : "No Rating";
  };



  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch(`${BaseUrl}/api/reviews`);
        const data = await response.json();
        setReviews(data);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    fetchReviews();
  }, []);


  const handleSelectPackage = (pkg) => {
    setSelectedPackage(pkg);
    navigate("/tour-detail", { state: { selectedPackage: pkg } });
  };
  const handleBack = () => {
    setSelectedPackage(null);
  };

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/api/getpackages`);
        setPackages(response.data.packages);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch packages");
        setLoading(false);
      }
    };

    fetchPackages();
  }, []);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/api/getalllocations`);
        setLocations(response.data.locations);
      } catch (err) {
        console.error("Error fetching locations:", err);
      }
    };

    fetchLocations();
  }, []);

  // const filteredPackages = searchTerm
  //   ? packages.filter((pkg) =>
  //       pkg.locationId.name.toLowerCase().includes(searchTerm.toLowerCase())
  //     )
  //   : packages;
  const whyChooseUs = [
    {
      imageSrc: require("assets/kkraimg/whychooseus1.png"),
      title: "Far More Value",
      description:
        "Travels with exclusive deals, premium experiences, and unmatched support.",
    },
    {
      imageSrc: require("assets/kkraimg/whychooseus2.png"),
      title: "Trusted Travelers",
      description: "Responsible and trustworthy travelling agents.",
    },
    {
      imageSrc: require("assets/kkraimg/whychooseus3.png"),
      title: "Exclusive Member Benefits",
      description:
        "Special discounts, upgrades, and priority bookings with our exclusive membership program.",
    },
    {
      imageSrc: require("assets/kkraimg/whychooseus4.png"),
      title: "Top-Rated Local Guides",
      description: "Explore destinations with trusted, top-rated local guides.",
    },
  ];

  return (
    <>
      <Navbar />
      <main style={{ overflowX: "hidden" }}>
        <LandingHeader
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setConfirmedSearchTerm={setConfirmedSearchTerm}
          confirmedSearchTerm={confirmedSearchTerm}
          onSearch={setConfirmedSearchTerm}
        />
        <section className="block lg:pt-0 lg:mx-12 sm:mx-0 xs:mx-0">
          <Container maxWidth="lg">
            <div className="rounded-xl">
              <div className="text-center">
                <h4 className="text-[#000000] text-3xl font-bold">Packages</h4>
              </div>
              <div className="pt-10">
                {selectedPackage ? (
                  <div>
                    <button onClick={handleBack} className="mb-4 text-blue-600">
                      ← Back
                    </button>
                    <TourDetail
                      title={selectedPackage.title}
                      rating={selectedPackage.rating}
                      days={selectedPackage.days}
                      nights={selectedPackage.nights}
                      hours={selectedPackage.hours}
                      price={selectedPackage.price}
                      image={
                        Array.isArray(selectedPackage.image)
                          ? selectedPackage.image
                          : [selectedPackage.image]
                      }
                      description={selectedPackage.description}
                      date={selectedPackage.date}
                      startTime={selectedPackage.startTime}
                      endTime={selectedPackage.endTime}
                      policy={selectedPackage.policy}
                      pickupTime={selectedPackage.pickupTime}
                      dropoffTime={selectedPackage.dropoffTime}
                    />
                  </div>
                ) : (
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    {packages &&
                      Array.isArray(packages) &&
                      packages.map((pkg, index) => {
                        const averageRating = calculateAverageRating(pkg.title);

                        return (
                          <div key={index} onClick={() => handleSelectPackage(pkg)}>
                            <HomeCard
                              title={pkg.title}
                              rating={averageRating} // Display calculated rating
                              days={pkg.days}
                              nights={pkg.nights}
                              hours={pkg.hours}
                              price={pkg.price}
                              image={Array.isArray(pkg.image) ? pkg.image : [pkg.image]}
                            />
                          </div>
                        );
                      })}

                  </div>
                )}
              </div>
            </div>
          </Container>
        </section>
        <section className="block lg:pt-0 lg:mx-12 sm:mx-0 xs:mx-0">
          <Container maxWidth="lg">
            <Services />
          </Container>
        </section>
        <section className="mt-5 container mx-auto px-14 items-center">
          <div className="text-center">
            <h4 className="text-[#000000] text-3xl font-bold">
              Why Choose US?
            </h4>
            <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">our services have been trusted by world travelers.</p>
          </div>
          <section style={{ backgroundImage: `url(${whyChooseUSbg})`, backgroundSize: "cover", backgroundPosition: "center" }}>
            <section className="block py-10 mt-12 lg:pt-0">
             
                <div className="flex w-full pt-10 gap-4 md:gap-4 xs:flex-wrap sm:justify-center xs:justify-center sm:flex-wrap lg:flex-row">
                  {whyChooseUs.map((item, index) => (
                    <div
                      key={index}
                      className="bg-white w-full md:w-[260px] lg:w-[260px] z-10 py-10 rounded-xl cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out hover:shadow-lg"
                    >
                      <div className="flex flex-col items-center justify-center">
                        <div className="w-[60px] h-[60px]">
                          <img src={item.imageSrc} alt={item.title} />
                        </div>
                        <div className="text-center">
                          <h4 className="text-[#000000] pt-3 text-lg font-bold">
                            {item.title}
                          </h4>
                          <p className="text-[#858585] pt-3 text-base font-normal w-auto mx-3">
                            {item.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}

                </div>
            
            </section>
          </section>
        </section>

        <section className="block py-10 lg:pt-0 lg:mx-12 sm:mx-0 xs:mx-0">
          <Container maxWidth="lg">
            <div className="w-full mt-8">
              <div className="text-center">
                <h4 className="text-[#000000] text-3xl font-bold">
                  Trending Destinations
                </h4>
                <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">Explore the most popular destinations, offering unique experiences and adventures.</p>
              </div>
              <div className="md:flex w-full h-full md:h-[600px] mt-5 ">
                <div className="relative w-full">
                  <img
                    className="h-[100%] w-full rounded-l-md object-cover"
                    src={require("assets/kkraimg/desert.jpg")}
                  />
                </div>
                <div className="w-full md:flex md:flex-col">
                  <div className="w-full md:flex">
                    <div className="relative w-full group">
                      <img
                        className="h-[300px] w-full object-cover transform transition-transform group-hover:scale-105"
                        src={require("assets/kkraimg/cruise.jpeg")}
                      />
                    </div>
                    <div className="relative w-full group">
                      <img
                        className="h-[300px] rounded-tr-md w-full object-cover transform transition-transform group-hover:scale-105"
                        src={require("assets/kkraimg/burjtop.jpeg")}
                      />
                    </div>
                    <div className="relative w-full group">
                      <img
                        className="h-[300px] w-full rounded-br-md object-cover transform transition-transform group-hover:scale-105"
                        src={require("assets/kkraimg/destinationimg1.png")}
                      />
                    </div>
                  </div>
                  <div className="md:flex">
                    <div className="relative w-full group">
                      <img
                        className="h-[300px] w-full object-cover transform transition-transform group-hover:scale-105"
                        src={require("assets/kkraimg/landscape.jpg")}
                      />
                    </div>
                    <div className="relative w-full group">
                      <img
                        className="h-[300px] w-full rounded-br-md object-cover transform transition-transform group-hover:scale-105"
                        src={require("assets/kkraimg/dubai-frame.jpg")}
                      />
                    </div>
                    <div className="relative w-full group">
                      <img
                        className="h-[300px] w-full rounded-br-md object-cover transform transition-transform group-hover:scale-105"
                        src={require("assets/kkraimg/destinationimg2.png")}
                      />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
        <section className="block py-10 lg:pt-0 lg:mx-12 sm:mx-0 xs:mx-0">
          <Container maxWidth="lg">
            <Influencers />
          </Container>
        </section>
        {/* <TravelDeals /> */}
        <section>
          {/* <OurBlogs /> */}
          <ReviewSection />
        </section>
      </main>


      {/* <Footerbn /> */}
    </>
  );
}
