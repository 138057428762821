import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BaseUrl } from "../BaseUrl";
import { FaSpinner } from "react-icons/fa";
import { toast } from "react-toastify";
import ReviewsCarousel from "./reviewCoursel";
import axios from "axios";

const ServiceDetails = () => {
  const { id } = useParams();
  const [service, setService] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    adults: 0,
    children: 0,
    number: "",
    bookingDate: "",
  });
  const [reviewData, setReviewData] = useState({
    name: "",
    description: "",
    review_start: 0,
    
  });
  const [bookingLoading, setBookingLoading] = useState(false);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchServiceDetails = async () => {
      try {
        const response = await fetch(`${BaseUrl}/api/services/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch service details");
        }
        const data = await response.json();
        setService(data);
      } catch (error) {
        console.error("Error fetching service details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchServiceDetails();
  }, [id]);
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch(`${BaseUrl}/api/reviews`);
        const data = await response.json();
        const serviceReviews = data?.filter((review) => review.type == "service");
        setReviews(serviceReviews);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    fetchReviews();
  }, []);
  const handleStarClick = (rating) => {
    setReviewData({ ...reviewData, review_start: rating });
  };

  const handleReviewChange = (e) => {
    const { name, value } = e.target;
    setReviewData({ ...reviewData, [name]: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("name", reviewData.name);
    formData.append("description", reviewData.description);
    formData.append("review_start", reviewData.review_start);
    formData.append("type", "service");
    formData.append("service_id", id);

    try {
      const response = await axios.post(`${BaseUrl}/api/review`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      toast.success(response.data.msg || "Review submitted successfully!");
      setReviewData({
        name: "",
        description: "",
        review_start: 0,
      });
      setShowReviewForm(false);
    } catch (error) {
      console.error("Error submitting review:", error);
      toast.error("Failed to submit review. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBookingLoading(true);

    const bookingData = {
      ...formData,
      title: service.title,
      duration: service.duration,
      date: service.date,
      price: service.price,
      paymentIntentId: "",
    };

    try {
      const response = await fetch(`${BaseUrl}/api/service-booking`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bookingData),
      });

      if (!response.ok) {
        throw new Error("Failed to book service");
      }

      const result = await response.json();
      toast.success(result.msg);
      setFormData({ name: "", email: "", adults: 0, children: 0, number: "" });
    } catch (error) {
      console.error("Error booking service:", error);
    } finally {
      setBookingLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!service) {
    return <div>Service not found.</div>;
  }

  return (
    <div className="pt-[100px]">
      <div className="flex flex-col md:flex-row">
        <div className="min-h-screen p-8 md:w-2/3 pr-4">
          <h1 className="text-3xl font-semibold mb-4">{service.name}</h1>
          <img
            src={service.image}
            alt={service.name}
            className="w-full h-64 object-cover mb-6"
          />
          <p
            dangerouslySetInnerHTML={{
              __html: service.description.replace(/\n/g, "<br/>"),
            }}
          />
        </div>
        <div className="md:w-1/3 flex items-center justify-center">
          <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-8">
            <h2 className="text-2xl font-bold mb-6 text-center">
              Booking Form
            </h2>
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label className="block text-gray-700 font-semibold mb-2">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                  placeholder="Enter your name"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 font-semibold mb-2">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                  placeholder="Enter your Email"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 font-semibold mb-2">
                  Phone Number
                </label>
                <input
                  type="text"
                  name="number"
                  value={formData.number}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                  placeholder="Enter your Phone Number"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 font-semibold mb-2">
                  Booking Date
                </label>
                <input
                  type="date"
                  name="bookingDate"
                  value={formData.bookingDate}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                  placeholder="Enter your Pick Up Date"
                  required
                />
              </div>

              <div>
                <label className="block text-gray-700 font-semibold mb-2">
                  Adults
                </label>
                <input
                  type="number"
                  name="adults"
                  value={formData.adults}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                  placeholder="Number of adults"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 font-semibold mb-2">
                  Children
                </label>
                <input
                  type="number"
                  name="children"
                  value={formData.children}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                  placeholder="Number of children"
                  required
                />
              </div>
              <button
                disabled={bookingLoading}
                type="submit"
                className="w-full py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition-all duration-300"
              >
                {bookingLoading ? (
                  <div className="flex items-center justify-center">
                    <FaSpinner className="animate-spin mr-2" />
                    Processing...
                  </div>
                ) : (
                  "Book now"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="px-10">
      <div className="pt-4">
        <button
          className="bg-blue-500 text-white py-2 px-4 rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300 ease-in-out"
          onClick={() => setShowReviewForm(!showReviewForm)}
        >
          {showReviewForm ? "Close Review Form" : "Give Review"}
        </button>
      </div>
      {showReviewForm && (
        <div className="p-4 bg-gray-100 rounded-lg mt-4 shadow-md">
          <h2 className="text-xl font-bold mb-4">Submit Your Review</h2>
          <form onSubmit={handleReviewSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold mb-2">
                Rating
              </label>
              <div className="flex space-x-2">
                {[1, 2, 3, 4, 5].map((star) => (
                  <button
                    key={star}
                    type="button"
                    className={`text-2xl ${
                      reviewData.review_start >= star
                        ? "text-yellow-400"
                        : "text-gray-400"
                    }`}
                    onClick={() => handleStarClick(star)}
                  >
                    ★
                  </button>
                ))}
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold mb-2">
                Name
              </label>
              <input
                type="text"
                name="name"
                value={reviewData.name}
                onChange={handleReviewChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold mb-2">
                Description
              </label>
              <textarea
                name="description"
                value={reviewData.description}
                onChange={handleReviewChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                required
              ></textarea>
            </div>
            <button
              type="submit"
              disabled={loading}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-all"
            >
              {loading ? "Submitting..." : "Submit Review"}
            </button>
          </form>
        </div>
      )}
</div>
      <ReviewsCarousel reviews={reviews} />
    </div>
  );
};

export default ServiceDetails;
