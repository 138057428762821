import React, { useState } from 'react';
import { Calendar, Users, Clock, Map, Camera, Coffee } from 'lucide-react';

const GuidedToursForm = () => {
  const [selectedTours, setSelectedTours] = useState([]);

  const tourOptions = [
    { id: 'oldDubai', name: 'Old Dubai Heritage Walk', icon: <Map size={24} /> },
    { id: 'modernDubai', name: 'Modern Dubai Skyscrapers', icon: <Camera size={24} /> },
    { id: 'desertSafari', name: 'Desert Safari Adventure', icon: <Coffee size={24} /> },
  ];

  const toggleTour = (tourId) => {
    setSelectedTours((prev) =>
      prev.includes(tourId) ? prev.filter((id) => id !== tourId) : [...prev, tourId]
    );
  };

  return (
    <div className="max-w-4xl mx-auto p-8  rounded-xl shadow-xl mt-10">
      <h2 className="text-4xl font-extrabold text-center mb-8 text-blue-600">
        Discover Dubai with Local Experts
      </h2>

      <form className="space-y-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {tourOptions.map((tour) => (
            <div
              key={tour.id}
              onClick={() => toggleTour(tour.id)}
              className={`cursor-pointer p-4 rounded-lg transition-all duration-300 ease-in-out transform hover:scale-105 ${
                selectedTours.includes(tour.id)
                  ? 'bg-blue-600 text-white shadow-lg'
                  : 'bg-white text-blue-600 shadow'
              }`}
            >
              <div className="flex items-center justify-center mb-3">{tour.icon}</div>
              <p className="text-center font-semibold">{tour.name}</p>
            </div>
          ))}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col">
            <label htmlFor="tourDate" className="mb-2 font-semibold ">
              Tour Date
            </label>
            <div className="relative">
              <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-500" size={20} />
              <input
                type="date"
                id="tourDate"
                className="w-full pl-10 pr-3 py-3 border-2 border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white"
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label htmlFor="groupSize" className="mb-2 font-semibold ">
              Group Size
            </label>
            <div className="relative">
              <Users className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-500" size={20} />
              <select
                id="groupSize"
                className="w-full pl-10 pr-3 py-3 border-2 border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white appearance-none"
              >
                <option value="1-2">1-2 People</option>
                <option value="3-5">3-5 People</option>
                <option value="6-10">6-10 People</option>
                <option value="10+">10+ People</option>
              </select>
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <label htmlFor="preferredTime" className="mb-2 font-semibold ">
            Preferred Start Time
          </label>
          <div className="relative">
            <Clock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-500" size={20} />
            <input
              type="time"
              id="preferredTime"
              className="w-full pl-10 pr-3 py-3 border-2 border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white"
            />
          </div>
        </div>

        <div className="flex flex-col">
          <label htmlFor="specialInterests" className="mb-2 font-semibold ">
            Special Interests
          </label>
          <textarea
            id="specialInterests"
            rows="3"
            placeholder="Tell us about your interests (e.g., history, architecture, cuisine)"
            className="w-full px-3 py-2 border-2 border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white"
          ></textarea>
        </div>

        <div className="flex flex-col">
          <label className="mb-2 font-semibold ">Language Preference</label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
            {['English', 'Arabic', 'Spanish', 'Chinese', 'French', 'German'].map((lang) => (
              <label key={lang} className="flex items-center space-x-2">
                <input type="checkbox" className="form-checkbox text-blue-600 focus:ring-blue-500" />
                <span>{lang}</span>
              </label>
            ))}
            
          </div>
        </div>





        <button
          type="submit"
          className="w-full bg-blue-600 text-white py-4 rounded-md hover:bg-blue-600 transition duration-300 ease-in-out text-lg font-semibold shadow-lg"
        >
          Book Your Dubai Adventure
        </button>
      </form>
    </div>
  );
};

export default GuidedToursForm;
