import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const renderRating = (rating) => {
  return (
    <div className="flex justify-center items-center">
      {Array.from({ length: 5 }, (_, index) => (
        <span
          key={index}
          className={`text-[24px] ${
            index < rating ? "text-yellow-500" : "text-gray-300"
          }`}
        >
          ★
        </span>
      ))}
      <span className="text-sm text-gray-700 pl-2">{rating}/5</span>
    </div>
  );
};

const ReviewsCarousel = ({ reviews }) => {
  const settings = {
    dots: true,
    infinite: reviews.length>3,
    speed: 500,
    slidesToShow: 4, 
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 640,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  return (
    <div className="mt-8 p-6 bg-white shadow-lg rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold px-4">
          Customer Reviews ({" "}
          <span className="text-lg text-gray-900">
            {reviews.length} {reviews.length === 1 ? "Review" : "Reviews"}
          </span>{" "}
          )
        </h2>
      </div>
      {reviews.length > 0 ? (
        <Slider {...settings}>
          {reviews.map((review, index) => (
            <div key={index} className="px-4">
              <div className="p-4 border rounded-lg shadow-md">
                <h3 className="text-lg font-semibold">{review.name}</h3>
                <div className="flex items-center">
                  {renderRating(review.review_start)}
                </div>
                <p className="text-gray-700">{review.description}</p>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <p className="text-gray-500">No reviews available yet.</p>
      )}
    </div>
  );
};

export default ReviewsCarousel;
