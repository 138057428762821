import React from "react";
import { FaClock, FaBolt, FaWhatsapp } from "react-icons/fa";
import ReactWhatsapp from "react-whatsapp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function HomeCard({ title, days, price, rating, image = [] }) {

  const getRatingCategory = (averageRating) => {
    if (averageRating >= 4) {
      return "Excellent";
    } else if (averageRating <= 4 && averageRating >= 3) {
      return "Good";
    }
    else if (averageRating >= 2 && averageRating <= 3) {
      return "Average";
    } 
     else {
      return "Needs Improvement";
    }
  };

  const ratingCategory = rating ? getRatingCategory(rating) : null;

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer border border-gray-200">
      <Slider {...settings} className="h-48">
        {Array.isArray(image) ? (
          image.map((img, index) => (
            <div key={index}>
              <img
                src={img}
                alt={`Slide ${index + 1}`}
                className="w-full h-48 object-contain"
              />
            </div>
          ))
        ) : (
          <div>
            <img
              src={image}
              alt="Default Slide"
              className="w-full h-48 object-contain"
            />
          </div>
        )}
      </Slider>
      <div className="p-4">
        <div className="flex justify-between">
          <div>
          <h3 className="text-lg font-semibold mb-2 truncate">{title}</h3>
          </div>
        <div>
          {rating ? (
            <p className="text-yellow-500 font-semibold">
              {rating}/5 - {ratingCategory}
            </p>
          ) : (
            <p className="text-gray-500">No reviews yet</p>
          )}
        </div>
        </div>
        
        <div className="flex justify-between">
          <div>
            <div className="">
              <div className="flex justify-start gap-x-2 items-center text-sm font-semibold text-gray-600 mb-2">
                <FaClock />
                <span> {days} Days ...</span>
              </div>
            </div>
            <div className="flex gap-x-2 items-center text-sm font-semibold text-orange-500">
              <FaBolt />
              <span> {price} AED</span>
            </div>
          </div>
          <div>
          <ReactWhatsapp
            number="+971551085136"
            message="Could you tell me about your tour Packages?"
            className="flex items-center gap-2 bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg shadow-md transition-all duration-300"
          >
            <FaWhatsapp className="text-xl" />
            <span>WhatsApp</span>
          </ReactWhatsapp>
          </div>
        </div>
      </div>
    </div>
  );
}
