import React from "react";
import { useLocation, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import BnbNavMob from "components/NavBar/BnbNavMob";

export default function Navbar() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const location = useLocation();
  const isHome = location.pathname === "/";

  return (
    <div className="w-full">
      <nav
        className={`absolute w-full top-0 left-0 z-50 text-white ${isHome ? "" : "bg-gray-800"
          }`}
      >
        <Grid container spacing={2} className="h-24 bnbNvar_wrapper_home">
          <Grid item xs={4} sm={3} className="flex items-center justify-center">
            <div className="bnbLogo">
              <div className="relative inline-block py-2 ml-4 text-sm font-bold leading-relaxed whitespace-nowrap">
                <Link to="/">
                  <div className="">
                    <img
                      className="object-fit w-[100px] pt-[3px]"
                      src={require("assets/kkraimg/kkra_logo.png")}
                      alt="KKRA Logo"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </Grid>

          <Grid item xs={8} sm={8}>
            <ul className="items-center justify-end hidden h-full bnbNav-items lg:flex">
              <li className="transition-all duration-300 ease-in-out hover:text-yellow-500 hover:scale-105">
                <Link to="/">Home</Link>
              </li>
              <li className="transition-all duration-300 ease-in-out hover:text-yellow-500 hover:scale-105">
                <Link to="/aboutus">About Us</Link>
              </li>
              <li className="transition-all duration-300 ease-in-out hover:text-yellow-500 hover:scale-105">
                <Link to="/package">Packages</Link>
              </li>
              {/* <li className="transition-all duration-300 ease-in-out hover:text-yellow-500 hover:scale-105">
                <Link to="/subscription">Subscription</Link>
              </li> */}
              <li className="transition-all duration-300 ease-in-out hover:text-yellow-500 hover:scale-105">
                <Link to="/contactus">Contact Us</Link>
              </li>
            </ul>

            {!isHome && (
              <button
                className="block px-3 py-1 text-xl leading-none bg-transparent border border-transparent border-solid rounded outline-none cursor-pointer lg:hidden focus:outline-none absolute top-8 right-4"
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <i className="text-white fas fa-bars"></i>
              </button>
            )}
          </Grid>
        </Grid>

        {/* Mobile Menu */}
        {navbarOpen && (
          <div className="lg:hidden">
            <BnbNavMob navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen} />
          </div>
        )}
      </nav>
    </div>
  );
}