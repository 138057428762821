import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BaseUrl } from "BaseUrl";

// Function to render stars based on rating
const renderRating = (rating) => {
  return (
    <div className="flex justify-center items-center mt-3">
      {Array.from({ length: 5 }, (_, index) => (
        <span
          key={index}
          className={`text-xl ${index < rating ? "text-yellow-500" : "text-gray-300"}`}
        >
          ★
        </span>
      ))}
    </div>
  );
};

const ReviewSection = () => {
  const [reviews, setReviews] = useState([]);

  const settings = {
    dots: true,
    infinite: reviews.length > 3,
    speed: 600,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  // Fetch reviews from API
  const fetchReviews = async () => {
    try {
      const response = await fetch(`${BaseUrl}/api/reviews`);
      const data = await response.json();
      setReviews(data);
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8 bg-gray-50">
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
          What Our Clients Say
        </h2>
        <p className="mt-4 text-lg text-gray-600">Hear from our happy customers</p>
      </div>

      <div className="mt-10 max-w-6xl mx-auto">
        <Slider {...settings}>
          {reviews.map((testimonial, index) => (
            <div key={index} className="px-4">
              <div className="bg-white shadow-lg rounded-2xl p-8 text-center border border-gray-200 transition duration-300 hover:shadow-xl">
                
                {/* Profile Image Placeholder */}
                <div className="w-20 h-20 mx-auto rounded-full bg-gray-200 flex items-center justify-center text-gray-600 font-semibold text-2xl">
                  {testimonial.name?.charAt(0).toUpperCase()}
                </div>

                <h3 className="text-lg font-semibold text-gray-900 mt-4">
                  {testimonial.name}
                </h3>
                <p className="text-sm text-gray-500">{testimonial.company}</p>

                <p className="text-gray-700 italic text-lg mt-4 leading-relaxed">
                  “{testimonial.description}”
                </p>

                <div className="pt-4">{renderRating(testimonial.review_start)}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ReviewSection;
