import React, { useState } from "react";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FaFacebookF, FaWhatsapp, FaYoutube, FaInstagram } from 'react-icons/fa';
import { IoCall, IoMail } from "react-icons/io5";
import { FaMapMarkerAlt } from 'react-icons/fa';
import { toast } from "react-toastify";
import axios from "axios";
import { BaseUrl } from "BaseUrl";

function Footerbn() {
  const [phone, setPhone] = useState("");
   const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
     const [error, setError] = useState(null);

  const images = [
    { id: 1, src: require("assets/kkraimg/footer1.png"), alt: "Image 1" },
    { id: 2, src: require("assets/kkraimg/footer2.png"), alt: "Image 2" },
    { id: 3, src: require("assets/kkraimg/footer3.png"), alt: "Image 3" },
    { id: 4, src: require("assets/kkraimg/footer4.png"), alt: "Image 4" },
  ];

  const handleSubscribe = async () => {
      if (!email) {
        toast.error("Please enter a valid email address");
        return;
      }
  
      setLoading(true); // Show loading state
  
      try {
        const apiUrl = `${"http://localhost:5000" || BaseUrl}/api/subscribe`;
        const response = await axios.post(apiUrl, { email });
  
        if (response.status === 200) {
          toast.success(response.data.message);
          setEmail("");
        }
      } catch (err) {
        if (err.response && err.response.data) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Something went wrong. Please try again.");
        }
      } finally {
        setLoading(false);
      }
    };
  

  return (
    <div className="bg-gray-100 border  py-10">

       <div className="mb-7 rounded-lg py-8 px-12 mx-auto max-w-screen-xl">
        <div className="md:ml-24">
          <div className="text-center flex lg:flex-row flex-col items-center lg:justify-between gap-x-16">
            {/* Heading */}
            <h2 className="md:text-3xl text-xl text-blue-500 font-bold md:ml-12">
              Get Updated The Latest Newsletter
            </h2>

            {/* Input + Button */}
            <div className="mx-auto">
              <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
                <input
                  type="email"
                  placeholder="Enter your Email"
                  className="w-full sm:w-64 px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button
                  onClick={handleSubscribe}
                  className="w-full sm:w-auto px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300 flex items-center justify-center"
                  disabled={loading} // Disable button when loading
                >
                  {loading ? (
                    <svg className="animate-spin h-5 w-5 mr-3 border-2 border-white border-t-transparent rounded-full" viewBox="0 0 24 24"></svg>
                  ) : (
                    "Subscribe Now"
                  )}
                </button>

              </div>
            </div>
          </div>
        </div>
       

      </div>

      <div className="mb-7 rounded-lg px-12 mx-auto max-w-screen-xl border-b-2 border-gray-300">
        </div>

      <div className="container mx-auto px-12">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2">
          <div className="space-y-4">
            <Link to="/">
              <img
                src={require("assets/kkraimg/kkra_logo.png")}
                className="w-24 h-16"
                alt="KKRA Logo"
              />
            </Link>
            <p className="text-lg"> Airline services ensure safe, comfortable travel with convenient booking and in-flight amenities for a seamless experience. </p>
            <div className="flex space-x-4">
              <a href="https://www.facebook.com/profile.php?id=61567799030782&mibextid=ZbWKwL" className="text-blue-600 hover:text-blue-800">
                <FaFacebookF className="text-4xl" />
              </a>
              <a href="https://wa.me/" className="text-green-500 hover:text-green-700">
                <FaWhatsapp className="text-4xl" />
              </a>
              <a href="https://www.youtube.com/channel/UCi9ThwQsig3Xph8LX8Tq4Lw" className="text-blue-400 hover:text-blue-600">
                <FaYoutube className="text-4xl" />
              </a>
              <a href="https://www.instagram.com/kkratraveltours/profilecard" className="text-pink-600 hover:text-pink-800">
                <FaInstagram className="text-4xl" />
              </a>
            </div>
          </div>

          {/* Quick Links Section */}
          <div className="space-y-4">
            <h3 className="text-blue-500 hover:text-blue-600 text-2xl font-bold">Quick Links</h3>
            <Link to="/" className="block text-black hover:text-blue-600">Home</Link>
            <Link to="/about" className="block text-black hover:text-blue-600">About Us</Link>
            <Link to="/contact" className="block text-black hover:text-blue-600">Contact Us</Link>
            <Link to="/subscription" className="block text-black hover:text-blue-600">Subscription</Link>
            <Link to="/features" className="block text-black hover:text-blue-600">Features</Link>
            <Link to="/packages" className="block text-black hover:text-blue-600">Packages</Link>
          </div>

          {/* Contact Information Section */}
          <div className="space-y-4">
            <h3 className="text-blue-500 hover:text-blue-600 text-2xl font-bold">Get In Touch</h3>
            <div className="flex items-center gap-2">
              <IoCall className="text-2xl text-green-600" />
              <a href="tel:+971551085136" className="text-black hover:text-blue-600">+971551085136</a>
            </div>
            <div className="flex items-center gap-2">
              <IoMail className="text-2xl" />
              <a href="mailto:info@kkratraveltours.com" className="text-black hover:text-blue-600">info@kkratraveltours.com</a>
            </div>
            <div className="flex gap-2">
              <FaMapMarkerAlt className="text-2xl text-red-600" />
              <p className="text-black hover:text-blue-600">
                Office 101, First Floor Street # 26 Ahmad Building Hor Al ANZ Dubai, UAE
              </p>
            </div>
          </div>

          {/* Instagram Gallery Section */}
          <div className="space-y-4">
            <h3 className="text-blue-500 hover:text-blue-600 text-2xl font-bold">Instagram Post</h3>
            <div className="grid grid-cols-2 gap-4">
              {images.map((image) => (
                <img
                  key={image.id}
                  src={image.src}
                  alt={image.alt}
                  className="w-full h-24 object-cover rounded-md"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footerbn;