import React, { useEffect, useState } from "react";
import { BaseUrl } from "../../BaseUrl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const getRatingCategory = (averageRating) => {
  if (averageRating >= 4) {
    return "Excellent";
  } else if (averageRating <= 4 && averageRating >= 3) {
    return "Good";
  }
  else if (averageRating >= 2 && averageRating <= 3) {
    return "Average";
  } 
   else {
    return "Needs Improvement";
  }
};


const ServiceCard = ({ service,reviews }) => {
  console.log("reviews",reviews);
  const [expandedServiceId, setExpandedServiceId] = useState(null);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/service-details/${service._id}`);
  };

  const calculateAverageRating = () => {
    if (!reviews || reviews.length === 0) return "No Reviews"; 

    const totalRating = reviews.reduce(
      (sum, review) => sum + (Number(review.review_start) || 0), 
      0
    ); 
    const averageRating = totalRating / reviews.length;

    return !isNaN(averageRating) ? averageRating.toFixed(1) : "No Rating";
  };

  const averageRating = calculateAverageRating();
  const ratingCategory = averageRating ? getRatingCategory(averageRating) : null;

  return (
    <div
      className="bg-white rounded-lg shadow-md p-6 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-xl cursor-pointer"
      onClick={handleClick}
    >
      <div>
        <div className="rounded-md mb-4">
          <img
            src={service?.image}
            alt={service?.title}
            className="rounded-md"
          />
        </div>
      </div>
      <div className="flex justify-between px-4">
        <div>
        <h3 className="text-xl font-semibold mb-2">{service?.title}</h3>
        </div>
        <div>
          {averageRating ? (
            <p className="text-yellow-500 font-semibold">
              {averageRating}/5 - {ratingCategory}
            </p>
          ) : (
            <p className="text-gray-500">No reviews yet</p>
          )}
        </div>
     
      </div>
      
      <p className="text-gray-600">
        {expandedServiceId === service?._id ? (
          <span
            dangerouslySetInnerHTML={{
              __html: service?.description.replace(/\n/g, "<br />"),
            }}
          />
        ) : (
          <span
            dangerouslySetInnerHTML={{
              __html: `${service?.description
                .slice(0, 120)
                .replace(/\n/g, "<br />")}...`,
            }}
          />
        )}
        {service?.description.length > 20 && (
          <button
            className="text-blue-500"
            onClick={(e) => {
              e.stopPropagation();
              setExpandedServiceId(
                expandedServiceId === service?._id ? null : service?._id
              );
            }}
          >
            {expandedServiceId === service?._id ? "View Less" : "View More"}
          </button>
        )}
      </p>

     

    </div>
  );
};

const Services = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const[reviews,setReviews]= useState([]);

   useEffect(() => {
      const fetchReviews = async () => {
        try {
          const response = await fetch(`${BaseUrl}/api/reviews`);
          const data = await response.json();
          setReviews(data);
        } catch (error) {
          console.error("Error fetching reviews:", error);
        }
      };
  
      fetchReviews();
    }, []);

  const fetchServices = async () => {
    try {
      const response = await fetch(`${BaseUrl}/api/services`);
      if (!response.ok) {
        throw new Error("Failed to fetch services");
      }
      const data = await response.json();
      setServices(data);
    } catch (error) {
      console.error("Error fetching services:", error);
      toast.error("Failed to load services.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchServices();
  }, []);

  return (
    <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-0">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-bold text-gray-900 sm:text-3xl md:text-3xl">
            Services We Offer
          </h1>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            Discover the best of Dubai with our premium travel services
          </p>
        </div>
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <span className="text-lg text-gray-600">Loading services...</span>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.length > 0 ? (
              services.map((service) => {
                const serviceReviews = reviews.filter(
                  (review) => review.service_id === service._id
                );

                return (
                  <ServiceCard
                    key={service._id}
                    service={service}
                    reviews={serviceReviews} // Pass only related reviews to the component
                  />
                );
              })
        ) : (
          <p className="text-center text-gray-500">No services available</p>
        )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Services;
